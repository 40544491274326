const Partners = () => {
  const partnerGroup = [
    { id: 1, logo: "cliente-1" },
    { id: 2, logo: "cliente-2" },
    { id: 3, logo: "cliente-3" },
    { id: 4, logo: "cliente-4" },
    { id: 5, logo: "cliente-5" },
    { id: 6, logo: "cliente-6" },
    { id: 7, logo: "cliente-12" },
    { id: 8, logo: "cliente-13" },
  ];
  return (
    <div className="max-w-[1366px] pt-12 m-auto">
      <div className="grid lg:grid-cols-8 md:grid-cols-4 grid-cols-2">
        {partnerGroup.map((item) => (
          <div
          key={item.id}
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            margin:'10px 20px'
          }}
          >
          <img
            src={`/images/partners/${item.logo}.png`}
            alt="socios"
            className="self-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
